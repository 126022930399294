import { Hill } from "@ecologi/react-components/src";
import { MountainsBackground } from "@ecologi/react-components/src/BusinessPageHero/components";
import { putCommasIntoNumber } from "@ecologi/shared";
import styled from "@emotion/styled";
import { useTotals } from "../../lib";

// This fixes a little visual bug with the svg mountain bg
const HillWrapper = styled.div`
  transform: translateY(-2px);
`;

export default function HillWithImpactStatsWidget(props: any) {
  let totalSubscribers = null;
  let totalTrees = 39000000;
  let totalCarbon = 1700000;

  const { data: countData, error } = useTotals();

  if (countData && !error) {
    const {
      totalTrees: t,
      totalCarbon: c,
      ...countSubscribersData
    } = countData;
    totalTrees = t;
    totalCarbon = c;
    totalSubscribers = Object.values(countSubscribersData).reduce<number>(
      (n, t: number) => n + t,
      0
    );
  }

  const subtitle = props.data.subtitle.replace(
    "{{numMembers}}",
    totalSubscribers ? putCommasIntoNumber(totalSubscribers) : "12,000+"
  );

  return (
    <>
      <MountainsBackground isBlock isGradientBg />
      <HillWrapper>
        <Hill
          title={props.data.title}
          subtitle={props.data.subtitle || subtitle}
          totalCarbon={props.data.totalCarbon || totalCarbon}
          totalTrees={props.data.totalTrees || totalTrees}
          isStandaloneWidget
        />
      </HillWrapper>
    </>
  );
}
