import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import numeral from "numeral";

import { EcologiTheme } from "../../theme";
import { rotatingTurbines } from "../../theme/rotatingTurbines";

const HillWrapperClip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.forest};
  clip-path: ellipse(70% 100% at 50% 100%);
  height: 100%;
  margin-left: -25%;
  position: relative;
  top: -185px;
  width: 150%;
  padding-bottom: 35px;

  @media ${(props) => props.theme.bp.mobileUp} {
    clip-path: ellipse(54% 100% at 50% 100%);
  }
`;

const HillWrapperStandalone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.colors.forest};
  padding-bottom: 35px;
`;

const HillTitle = styled.h3<{ isStandalone?: boolean }>`
  color: ${(props) => props.theme.colors.snowdrop};
  font-size: 34px;
  font-weight: bold;
  line-height: 1.2;
  padding: ${(props) =>
    props.isStandalone ? "20px 30px 16px" : "70px 40px 16px"};
  text-align: center;
  width: 100%;

  @media ${(props) => props.theme.bp.mobileUp} {
    font-size: 40px;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    &::after {
      content: " so far";
    }
  }
`;

const HillSubtitle = styled.h4`
  color: ${(props) => props.theme.colors.snowdrop};
  line-height: 1.3;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 50px;
  padding: 0 30px;
  text-align: center;
  width: 100%;

  @media ${(props) => props.theme.bp.mobileUp} {
    font-size: 26px;
  }
`;

const HillStats = styled.div`
  margin: 0 auto 40px;
  max-width: 900px;
  padding: 0 40px;
  position: relative;
  width: 100%;
  overflow: hidden;

  @media ${(props) => props.theme.bp.tabletUp} {
    display: flex;
    justify-content: center;

    &::after {
      background: ${(props) => props.theme.colors.forest};
      content: "";
      height: 120%;
      left: 50%;
      top: -10%;
      margin-left: -1px;
      position: absolute;
      width: 2px;
    }
  }
`;

const hillStatsBase = (props: { theme: EcologiTheme }) => css`
  border-top: 2px solid ${props.theme.colors.forest};
  display: flex;
  justify-content: center;

  @media ${props.theme.bp.tabletUp} {
    border-top: none;
  }
`;

const HillStatsTrees = styled.div`
  ${hillStatsBase};
`;

const HillDivider = styled.div`
  position: relative;
  width: 100%;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.2);

  @media ${(props) => props.theme.bp.tabletUp} {
    position: absolute;
    height: 100%;
    width: 3px;
    left: 50%;
  }
`;

const HillStatsCarbon = styled.div`
  ${hillStatsBase};

  @media ${(props) => props.theme.bp.tabletUp} {
    left: 20px;
    margin-left: 50px;
    position: relative;
  }
`;

const HillStatsNumbers = styled.div<{ isCarbon?: boolean }>`
  color: ${(props) => props.theme.colors.snowdrop};
  display: inline-block;
  padding: 30px;
  position: relative;
  left: 70px;
  width: 300px;

  h5 {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
    line-height: 1;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    opacity: 0.4;
    padding-right: 20px;
  }

  @media only screen and (min-width: 400px) {
    h5 {
      font-size: 34px;
    }

    p {
      font-size: 20px;
      padding-right: 0;
    }
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    width: 350px;

    h5 {
      font-size: 46px;
    }
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    p {
      font-size: 24px;
    }
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg id='tree_icon' data-name='tree icon' xmlns='http://www.w3.org/2000/svg' width='86' height='77.78'%3E%3Cdefs%3E%3Cstyle%3E.cls-2%7Bfill-rule:evenodd;fill:%2328c382%7D%3C/style%3E%3C/defs%3E%3Cpath id='Ellipse_522_copy_3' data-name='Ellipse 522 copy 3' d='M2949.08 3682.63c20.85 0 37.75 2.04 37.75 4.56s-16.9 4.56-37.75 4.56-37.75-2.04-37.75-4.56 16.9-4.56 37.75-4.56z' transform='translate(-2905 -3613.97)' fill-rule='evenodd' fill='%23222' opacity='.23'/%3E%3Cpath id='Shape_578_copy_7' data-name='Shape 578 copy 7' class='cls-2' d='M2937.66 3647.69s-10.32 1.05-18.29-11.92-14.36-13.75-14.36-13.75 27.3-13.2 37.35 2.38c10.36 16.06.8 22.28-4.7 23.29z' transform='translate(-2905 -3613.97)'/%3E%3Cpath id='Shape_578_copy_7-2' data-name='Shape 578 copy 7' class='cls-2' d='M2965.87 3639.91s8.81-.02 14.3-11.11 10.82-12.26 10.82-12.26-24.27-8.31-31.28 5.05c-7.23 13.77 1.43 17.97 6.16 18.32z' transform='translate(-2905 -3613.97)'/%3E%3Cpath id='Shape_586_copy_2' data-name='Shape 586 copy 2' d='M2945.76 3686.19s2.93 1.36 4.81.51 1.92-29.12 4.25-35.05 16.58-26.54 24.84-30.56c0 0 .76-2.14-2.13-1.98-5.51.31-15.59 13.92-21.34 21.56a54.9 54.9 0 00-5.45 9.45c.97-1.1-15.77-20.72-25-23.6-5.31-1.66-.58 2.77-.58 2.77s22.25 17.15 22.98 28.67-3.39 26.91-2.38 28.23z' transform='translate(-2905 -3613.97)' fill='%230c9e77' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 100px;
    left: -65px;
    position: absolute;
    top: 30px;
    transform: scale(-0.9, 0.9);
    width: 80px;
    z-index: 9999;

    ${(props) =>
      !props.isCarbon &&
      css`
        @media ${props.theme.bp.tabletUp} {
          left: -70px;
          top: 40px;
          transform: scale(-1.1, 1.1);
        }
      `};

    ${(props) =>
      props.isCarbon &&
      css`
        background-image: ${rotatingTurbines};
        transform: scale(1.3);
        left: -70px;

        @media ${props.theme.bp.tabletUp} {
          transform: scale(1.4);
        }
      `}
  }
`;

interface HillProps {
  title: string;
  subtitle: string;
  totalTrees: number;
  totalCarbon: number;
  isStandaloneWidget?: boolean;
}

export const Hill = ({
  title,
  subtitle,
  totalTrees,
  totalCarbon,
  isStandaloneWidget,
}: HillProps) => {
  const totalCarbonStr = numeral(totalCarbon)
    .format("0.0 a")
    .replace("m", "million");
  const totalTreesStr = numeral(totalTrees)
    .format("0.0 a")
    .replace("m", "million");

  const Wrapper = isStandaloneWidget ? HillWrapperStandalone : HillWrapperClip;

  return (
    <Wrapper>
      <HillTitle isStandalone={isStandaloneWidget}>{title}</HillTitle>
      <HillSubtitle>{subtitle}</HillSubtitle>
      <HillStats>
        <HillStatsTrees>
          <HillStatsNumbers>
            <h5>{totalTreesStr}</h5>
            <p>Trees funded</p>
          </HillStatsNumbers>
        </HillStatsTrees>
        <HillDivider />
        <HillStatsCarbon>
          <HillStatsNumbers isCarbon>
            <h5>{totalCarbonStr}</h5>
            <p>
              Tonnes of CO<sub>2</sub>e avoided
            </p>
          </HillStatsNumbers>
        </HillStatsCarbon>
      </HillStats>
    </Wrapper>
  );
};
